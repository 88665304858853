<template>
  <card-form>
    <template slot="title"> Finance </template>
    <template slot="form">
      <form-row>
        <template #left>
          <sun-label-group text="Fee (%)" class="flex-1 w-full">
            <asterix-input
              :id="`${entity}-fee`"
              :value="value.fee"
              :min="0"
              name="fee"
              placeholder="%"
              required="required"
              type="number"
              auto-validate
            />
          </sun-label-group>
        </template>
        <template #right>
          <sun-label-group text="Tech Cost (%)" class="flex-1 w-full">
            <asterix-input
              :id="`${entity}-tech-cost`"
              :value="value.techCost"
              :min="0"
              name="techCost"
              placeholder="%"
              required="required"
              type="number"
              auto-validate
            />
          </sun-label-group>
        </template>
      </form-row>
      <form-row>
        <template #left>
          <sun-label-group text="Type of Wallet">
            <sun-select
              name="walletType"
              :value="value.walletType"
              :options="walletTypes"
              :disabled="disabledWalletType"
              track-by="id"
              label="value"
              add-hex-color="orange"
              text-error="the filed is required"
              required="required"
              @input="value.walletType = $event"
            />
          </sun-label-group>
          <div v-if="disabledWalletType" class="flex flex-row italic mt-2 text-xs">
            <info-svg class="h-4 mr-2" /> Once the workspace is created it is not possible to modify this type of
            selection.
          </div>
        </template>
      </form-row>
    </template>
  </card-form>
</template>

<script>
import CardForm from '../../../atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import Finance from '@/entities/socialAudience/Finance';
import { WALLET_TYPES } from '@/model/modules/socialAudience/wallet/WalletType';
import InfoSvg from '@/components/icons/InfoSvg.vue';

export default {
  name: 'FinanceInfo',
  components: {
    FormRow,
    CardForm,
    AsterixInput,
    InfoSvg,
  },
  props: {
    entity: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      validation: value => typeof value.fee !== 'undefined' && typeof value.techCost !== 'undefined',
      default: () => new Finance(),
    },
    disabledWalletType: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    walletTypes: WALLET_TYPES,
  }),
};
</script>
