var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card-form",
    [
      _c("template", { slot: "title" }, [_vm._v(" Finance ")]),
      _c(
        "template",
        { slot: "form" },
        [
          _c("form-row", {
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      {
                        staticClass: "flex-1 w-full",
                        attrs: { text: "Fee (%)" },
                      },
                      [
                        _c("asterix-input", {
                          attrs: {
                            id: `${_vm.entity}-fee`,
                            value: _vm.value.fee,
                            min: 0,
                            name: "fee",
                            placeholder: "%",
                            required: "required",
                            type: "number",
                            "auto-validate": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "right",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      {
                        staticClass: "flex-1 w-full",
                        attrs: { text: "Tech Cost (%)" },
                      },
                      [
                        _c("asterix-input", {
                          attrs: {
                            id: `${_vm.entity}-tech-cost`,
                            value: _vm.value.techCost,
                            min: 0,
                            name: "techCost",
                            placeholder: "%",
                            required: "required",
                            type: "number",
                            "auto-validate": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("form-row", {
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "sun-label-group",
                      { attrs: { text: "Type of Wallet" } },
                      [
                        _c("sun-select", {
                          attrs: {
                            name: "walletType",
                            value: _vm.value.walletType,
                            options: _vm.walletTypes,
                            disabled: _vm.disabledWalletType,
                            "track-by": "id",
                            label: "value",
                            "add-hex-color": "orange",
                            "text-error": "the filed is required",
                            required: "required",
                          },
                          on: {
                            input: function ($event) {
                              _vm.value.walletType = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.disabledWalletType
                      ? _c(
                          "div",
                          { staticClass: "flex flex-row italic mt-2 text-xs" },
                          [
                            _c("info-svg", { staticClass: "h-4 mr-2" }),
                            _vm._v(
                              " Once the workspace is created it is not possible to modify this type of selection. "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }