var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("users-edit", {
    on: {
      "user-settings": _vm.setSettings,
      "context-role-changed": _vm.contextChanged,
    },
    scopedSlots: _vm._u([
      {
        key: "extra-cards",
        fn: function () {
          return [
            _vm.isClientRolSelected
              ? _c("finance-info", {
                  attrs: { entity: "finance", value: _vm.finance },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }