<template>
  <users-edit @user-settings="setSettings" @context-role-changed="contextChanged">
    <template #extra-cards>
      <finance-info v-if="isClientRolSelected" entity="finance" :value="finance" />
    </template>
  </users-edit>
</template>

<script>
import UsersEdit from '@/components/organisms/shared/users/UsersEdit';
import FinanceInfo from '@/components/organisms/shared/formFields/FinanceInfo';
import Finance from '@/entities/socialAudience/Finance';
import { ROLES } from '@/model/shared/roles';

export default {
  name: 'SocialAudienceUsersEdit',
  components: { UsersEdit, FinanceInfo },
  data: () => ({
    finance: {
      type: Finance,
      validation: value => typeof value.fee !== 'undefined' && typeof value.techCost !== 'undefined',
      default: () => new Finance(),
    },
    isClientRolSelected: {
      type: Boolean,
      value: false,
    },
  }),
  methods: {
    setSettings(settings) {
      this.finance = new Finance().create(settings.finance);
    },
    contextChanged(context) {
      this.isClientRolSelected = context.role === ROLES.CLIENT.id;
    },
  },
};
</script>
